import axios from "axios";
import { ref } from "vue"
// import router from "../router";

export default function useProduto() {
    
    const meta = ref([]);
    const status = ref(Boolean);
    const error = ref('');
    const message = ref('');
    const paramsSearch = ref({});

    const produtos = ref([]);
    const produto = ref([]);

    const getProdutos= async (page=1) => {
        const qs = new URLSearchParams(paramsSearch.value);
        let response =await axios.get('/produtos?page='+page+'&'+qs);
        produtos.value = response.data.data;
        meta.value = response.data.meta;
    };

    const getProduto = async (id) => {
        let response = await axios.get('/produtos/' + id);
        produto.value = response.data.data;
    };

    const destroyProduto = async (id) => {
        error.value = "";
        message.value = "";
        status.value = true;

        try {
            await axios.delete('/produtos/' + id).then((response) => {
                message.value = response.data.message;
            });
        } catch (e) {
            status.value = false;
            if(e.response.status === 422){
                error.value += e.response.data.errors + "<br />";
            }
        }
    };

    const storeProduto = async (data) => {
        
        error.value = "";
        message.value = "";
        status.value = true;

        try {
            await axios.post('/produtos', data).then((response) => {
                message.value = response.data.message;
            });
        } catch (e) {
            status.value = false;
            if(e.response.status === 422){
                for(const key in e.response.data.errors){
                    error.value += e.response.data.errors[key][0] + "<br />";
                }
            }
        }
    };

    const updateProduto = async (id) => {

        error.value = "";
        message.value = "";
        status.value = true;

        try {
            await axios.patch('/produtos/' + id, produto.value).then((response) => {
                message.value = response.data.message;
            });
        } catch (e) {
            status.value = false;
            if(e.response.status === 422){
                for(const key in e.response.data.errors){
                    error.value += e.response.data.errors[key][0] + "<br />";
                }
            }
        }
    };

    return {

        meta,
        error,
        status,
        message,
        paramsSearch,

        produtos,
        produto,
        getProduto,
        getProdutos,
        storeProduto,
        updateProduto,
        destroyProduto
    }
}