<template>
  <div v-if="rowsData.length > 0">
    <h3>Lista</h3>
    <div class="table-responsive">
      <table class="table table-striped table-hover table-bordered">
        <thead>
          <tr>
            <th width=168>...</th>
            <th v-for="field in fields" :key="field" >
              {{ field }}
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- Loop through the list get the each student data -->
          <tr v-for="row in rowsData" :key="row">
              <td>
                <button class="btn btn-primary btn-xs"
                  @click="()=>$router.push({ name: `${$router.currentRoute.value.meta.base}.edit`, params: { id: row.id },})">
                  editar
                </button>
                - 
                <button class="btn btn-primary btn-xs"
                  @click="optionsEdit(row.id)">
                  excluir
                </button>

                <slot name="opt_column_extra" v-bind:row="row"></slot>
              </td>

            <td v-for="field in fields" :key="field">
              {{ row[field] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <div class="card-footer">
      <div class="row  text-center" v-if="meta">
        <div class="col-12">
          <ul class="pagination justify-content-center">
            <template v-for="n in meta.last_page" :key="n">
              <li
                :class="{ 'page-item': true, active: n == meta.current_page }"
              >
                <a
                  class="page-link"
                  @click="getData(n)"
                  href="javascript:void(0);"
                  >{{ n }}</a
                >
              </li>
            </template>
          </ul>
          <p class="text-center">
            Exibindo {{ meta.from }} ate {{ meta.to }} de
            {{ meta.total }} registros
          </p>
        </div>
      </div>
      <div style="display: none" class="row">
        <div class="col-4">
          <p>
            Exibindo {{ meta.from }} ate {{ meta.to }} de
            {{ meta.total }} registros
          </p>
        </div>
        <div class="col-8">
          <ul class="pagination justify-content-end">
            <template v-for="n in meta.last_page" :key="n">
              <li
                :class="{ 'page-item': true, active: n == meta.current_page }"
              >
                <a
                  class="page-link"
                  @click="getData(n)"
                  href="javascript:void(0);"
                  >{{ n }}</a
                >
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "TableComponent",
  props: {
    //
    rowsData: {
      type: Array,
    },
    fields: {
      type: Array,
    },
    meta: {
      type: Object
    },
    getData: {
      type: Function
    },
    optionsEdit: {
      type: Function
    }
  },
};
</script>
